import React from "react"
import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
import Seo from "../components/Seo"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { StaticImage } from "gatsby-plugin-image"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import CallToAction from "../components/CallToAction"

const useStyles = makeStyles(theme => ({
  heroContainer: {
    position: "relative",
    height: "35rem",
    clipPath: "polygon(0 0, 100% 0, 100% 70%, 0% 100%)",
    zIndex: -1,
    [theme.breakpoints.down("xs")]: {
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
      height: "29rem",
    },
  },
  imgWrapper: {
    position: "absolute",
    width: "100%",
    zIndex: 1,
    height: 800,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.63)",
  },
  h1: {
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: "2.9375rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.25rem",
      paddingTop: '20%',
    },
  },
  h2: {
    color: theme.palette.common.white,
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 400,
    fontSize: "1.2375rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.05rem",
      lineHeight: "1.3rem",
    },
  },
  h3: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 400,
    fontSize: "2.25rem",
    // color: theme.palette.common.muted,
    // color: "rgb(218, 114, 53)",
  },
  lightImgCtr: {
    boxShadow: `0 30px 60px -10px rgb(0 0 0 / 30%), 0 18px 36px -18px rgb(0 0 0 / 33%)`,
  },
  h2article: {
    fontFamily: "Lora, Work Sans, sans-serif",
    fontSize: "1.3125rem",
    fontWeight: 700,
    lineHeight: "1.5rem",
    color: "rgb(218, 114, 53)",
  },
}))

export default function Newsroom() {
  const theme = useTheme()
  const classes = useStyles()
  const matchesMD = useMediaQuery(theme.breakpoints.between("xs", "sm"))

  return (
    <Layout>
      <Navbar />
      <Seo
        title="Newsroom"
        description="Read about Pond Lady Tonja Andreatta designing & installing waterscapes and swimming ponds in Medford, Grants Pass, and Ashland, Oregon."
      />
      <div className={classes.heroContainer}>
        <div className={classes.overlay}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ position: "relative", height: "80%" }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h1"
                align="center"
                className={classes.h1}
                gutterBottom
              >
                Andreatta Waterscapes In The News
              </Typography>
              <Typography variant="h2" align="center" className={classes.h2}>
                Read articles highlighting our expertise in constructing
                waterscapes.
              </Typography>
            </Grid>
          </Grid>
        </div>
        <StaticImage
          src="../assets/images/Mature Backyard Waterfall_Andreatta Waterscapes_Eagle Point Oregon.jpg"
          quality={90}
          placeholder="blurred"
          className={classes.imgWrapper}
          objectFit="cover"
          objectPosition="50% 25%"
          alt="Mature Backyard Waterfall Eagle Point Oregon"
        />
      </div>
      {/* <Grid
        container
        justifyContent="center"
        style={{ maxWidth: "99%", marginLeft: "auto", marginRight: "auto" }}
      >
        <Grid item xs={12} style={{ paddingTop: "3rem" }}>
          <Typography
            variant="h3"
            align="center"
            className={classes.h3}
            gutterBottom
          >
            The most popular news
          </Typography>
        </Grid>
      </Grid> */}
      <Container
        maxWidth="md"
        // style={{ paddingTop: "3rem", paddingBottom: "10rem" }}
        style={{ paddingTop: "6rem", paddingBottom: "10rem" }} // +3 padding to compensate for getting rid of the header
      >
        <Grid container spacing={matchesMD ? 0 : 10}>
          <Grid item md={6} xs={12}>
            <StaticImage
              src="../assets/images/pondtrademag1.jpg"
              className={classes.lightImgCtr}
              style={{
                minHeight: 220,
                width: "100%",
              }}
              imgStyle={{
                width: "100%",
                marginBottom: matchesMD ? undefined : "1.375em",
              }}
              alt="Contractor’s Corner I The Pond Lady Rocks
A Hybrid Fish/Swimming Pond"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="row"
              style={{
                marginTop: matchesMD ? "1em" : "4.375em",
                // paddingLeft: matchesMD ? undefined : "0em",
              }}
            >
              <Grid item xs={12} md={10}>
                <Typography
                  variant="h2"
                  className={classes.h2article}
                  style={{
                    paddingBottom: ".575em",
                    paddingTop: matchesMD ? ".75em" : undefined,
                  }}
                >
                  Contractor’s Corner | The Pond Lady Rocks
                </Typography>
              </Grid>
              <Grid item xs={12} md={10} style={{ minHeight: 48 }}>
                <Typography
                  variant="body1"
                  style={{
                    marginBottom: ".575em",
                    overflow: "hidden",
                    height: 48,
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                  }}
                >
                  A Hybrid Fish/Swimming Pond By Tonja Andreatta, Andreatta
                  Waterscapes Inc.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Aug 2018 · Pond Trade Magazine
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
              >
                <Button
                  variant="outlined"
                  component="a"
                  target="_blank"
                  href="https://www.pondtrademag.com/contractors-corner-i-the-pond-lady-rocks/"
                  rel="nofollow noreferrer"
                >
                  Read more
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row-reverse"
          spacing={matchesMD ? 0 : 10}
          style={{ paddingTop: "3rem" }}
        >
          <Grid item md={6} xs={12}>
            <StaticImage
              src="../assets/images/pondtrademag2.jpg"
              className={classes.lightImgCtr}
              style={{
                minHeight: 220,
                width: "100%",
              }}
              imgStyle={{
                width: "100%",
                marginBottom: matchesMD ? undefined : "1.375em",
              }}
              alt="Pond Construction | The “Picasso of Rock and Water” Spawns an Oregon Sanctuary"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="row"
              style={{
                marginTop: matchesMD ? "1em" : "4.375em",
                paddingLeft: matchesMD ? undefined : "3em",
              }}
            >
              <Grid item xs={12} md={10}>
                <Typography
                  variant="h2"
                  className={classes.h2article}
                  style={{
                    paddingBottom: ".575em",
                    paddingTop: matchesMD ? ".75em" : undefined,
                  }}
                >
                  The “Picasso of Rock and Water” Spawns an Oregon Sanctuary
                </Typography>
              </Grid>
              <Grid item xs={12} md={10} style={{ minHeight: 48 }}>
                <Typography
                  variant="body1"
                  style={{
                    marginBottom: ".575em",
                    overflow: "hidden",
                    height: 48,
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                  }}
                >
                  Pond Construction | Art in Stone and Water By Tonja Andreatta,
                  Andreatta Waterscapes Inc.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  June 2016 · Pond Trade Magazine
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
              >
                <Button
                  variant="outlined"
                  component="a"
                  href="https://www.pondtrademag.com/pond-construction-picasso-rock-water-spawns-oregon-sanctuary/"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Read more
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <CallToAction end={true} />
    </Layout>
  )
}
